import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { useTranslation } from "react-i18next"

const BenalconsProjects = () => {
  const { t } = useTranslation("projects")

  return (
    <div>
      <h2 className="uppercase font-inter font-bold font-xl pt-8 pb-5 lg:pb-6 text-center lg:text-4xl">
        {t("indexBenalcons")}
      </h2>
      <div className="grid lg:grid-cols-3 gap-8 place-items-center pb-8 px-2">
        <div className="flex flex-col items-center justify-center max-w-lg shadow-lg rounded-md m-2">
          <div className="flex flex-col lg:flex-row items-center justify-start flex-wrap">
            <StaticImage
              height={430}
              width={640}
              src="../../../images/techos.png"
              alt="Impermeabilización de techos y tejados."
              placeholder="tracedSVG"
              className="rounded-t-md shadow-md "
            />
            <div className="flex items-center justify-start py-2 w-full">
              <StaticImage
                height={58}
                width={60}
                src="../../../images/logo.png"
                alt="Impermeabilización de techos y tejados."
                placeholder="tracedSVG"
                className="rounded-md ml-2 flex-shrink-0"
              />
              <h3 className="text-center text-base font-montserrat-semi uppercase mx-auto">
                {t("project5")}
              </h3>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center max-w-lg shadow-lg rounded-md m-2">
          <div className="flex flex-col lg:flex-row items-center justify-start flex-wrap">
            <StaticImage
              height={430}
              width={640}
              src="../../../images/estructuras.png"
              alt="Impermeabilización de techos y tejados."
              placeholder="tracedSVG"
              className="rounded-t-md shadow-md "
            />
            <div className="flex items-center justify-start py-2 w-full">
              <StaticImage
                height={58}
                width={60}
                src="../../../images/logo.png"
                alt="Impermeabilización de techos y tejados."
                placeholder="tracedSVG"
                className="rounded-md ml-2 flex-shrink-0"
              />
              <h3 className="text-center text-base font-montserrat-semi uppercase mx-auto">
                {t("project6")}
              </h3>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center max-w-lg shadow-lg rounded-md m-2">
          <div className="flex flex-col lg:flex-row items-center justify-start flex-wrap">
            <StaticImage
              height={430}
              width={640}
              src="../../../images/reformas.png"
              alt="Impermeabilización de techos y tejados."
              placeholder="tracedSVG"
              className="rounded-t-md shadow-md "
            />
            <div className="flex items-center justify-start py-2 w-full">
              <StaticImage
                height={58}
                width={60}
                src="../../../images/logo.png"
                alt="Impermeabilización de techos y tejados."
                placeholder="tracedSVG"
                className="rounded-md ml-2 flex-shrink-0"
              />
              <h3 className="text-center text-base font-montserrat-semi uppercase mx-auto">
                {t("project7")}
              </h3>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center max-w-lg shadow-lg rounded-md m-2">
          <div className="flex flex-col lg:flex-row items-center justify-start flex-wrap">
            <StaticImage
              height={430}
              width={640}
              src="../../../images/reformas2.png"
              alt="Impermeabilización de techos y tejados."
              placeholder="tracedSVG"
              className="rounded-t-md shadow-md "
            />
            <div className="flex items-center justify-start py-2 w-full">
              <StaticImage
                height={58}
                width={60}
                src="../../../images/logo.png"
                alt="Impermeabilización de techos y tejados."
                placeholder="tracedSVG"
                className="rounded-md ml-2 flex-shrink-0"
              />
              <h3 className="text-center text-base font-montserrat-semi uppercase mx-auto">
                {t("project8")}
              </h3>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center max-w-lg shadow-lg rounded-md m-2">
          <div className="flex flex-col lg:flex-row items-center justify-start flex-wrap">
            <StaticImage
              height={430}
              width={640}
              src="../../../images/reformas3.png"
              alt="Impermeabilización de techos y tejados."
              placeholder="tracedSVG"
              className="rounded-t-md shadow-md "
            />
            <div className="flex items-center justify-start py-2 w-full">
              <StaticImage
                height={58}
                width={60}
                src="../../../images/logo.png"
                alt="Impermeabilización de techos y tejados."
                placeholder="tracedSVG"
                className="rounded-md ml-2 flex-shrink-0"
              />
              <h3 className="text-center text-base font-montserrat-semi uppercase mx-auto">
                {t("project9")}
              </h3>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center max-w-lg shadow-lg rounded-md m-2">
          <div className="flex flex-col lg:flex-row items-center justify-start flex-wrap">
            <StaticImage
              height={430}
              width={640}
              src="../../../images/reformas4.png"
              alt="Impermeabilización de techos y tejados."
              placeholder="tracedSVG"
              className="rounded-t-md shadow-md "
            />
            <div className="flex items-center justify-start py-2 w-full">
              <StaticImage
                height={58}
                width={60}
                src="../../../images/logo.png"
                alt="Impermeabilización de techos y tejados."
                placeholder="tracedSVG"
                className="rounded-md ml-2 flex-shrink-0"
              />
              <h3 className="text-center text-base font-montserrat-semi uppercase mx-auto">
                {t("project10")}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BenalconsProjects
