import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import BenalconsProjects from "../../components/sections/projects/benalcons"

const Reformas = () => (
  <Layout>
    <Seo title="Benalcons" />
    <BenalconsProjects />
  </Layout>
)

export default Reformas
